import React, { Component } from 'react'
import { withRouter } from "react-router";
import { connect } from "react-redux";
import SideMenu from '../../layOuts/SideMenu';
import Header from '../../layOuts/Header';
import ApiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import { Loader } from '../../layOuts/Loader';
import moment from 'moment';
import DataTable from "react-data-table-component";
import { ErrorPopup } from '../../layOuts/errorPopup';
import $ from 'jquery';
import ErrorHandlePopup from '../ErrorHandlePopup';
import Warnings from '../warnings/Warnings';
import SystemAlerts from '../warnings/SystemAlerts';

class Troubleshooting extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showRefreshModal: false,
            pageNo: 1,
            pageSize: 10,
            totalCount: 0,
            title: '',
            message: '',
            show: false,
            error: false,
            deviceLogData: [],
            showLoader: false,
            showError: false,
            troubleshooting: false,
            deviceSeverity: this.props?.location?.state?.deviceSeverity ? this.props?.location?.state?.deviceSeverity : '',
            tabChange: this.props?.location?.state?.tabChange ? this.props?.location?.state?.tabChange : false,
            selectedTab: this.props.location?.state?.selectedTab ? this.props.location?.state?.selectedTab : "Snapshots",
            newAlerts: this.props?.location?.state?.newAlerts ? this.props?.location?.state?.newAlerts : false,
            isAxiosError: false,
            countUpdate: Math?.random(),
            columns: [{
                name: "TimeStamp",
                selector: "created_date",
                sortable: true,
                cell: (row) => (
                    <span style={{ width: "auto" }}>
                        {moment.utc(row.created_date).local().format('DD MMMM YYYY hh:mm:ss A')}
                    </span>
                ),
            }, {

            },
            {
                name: "Download Debug Logs",
                selector: "download_url",
                sortable: true,
                //Fix for snapshot button design
                cell: (row) => (
                    row.download_url !== null ? <a href="#/" onClick={() =>
                        this.downloadDeviceLogs(row)}>Download</a> : "Download"
                )
            }],
        }
    }


    componentDidMount() {
        this.getDeviceLogList();
        if (this.state.tabChange === true) {
            $("#pills-ma-snapshot-tab").attr('class', 'nav-link');
            if (this.state.selectedTab === 'SystemAlerts') {
                $("#pills-sa-tab").attr('class', 'nav-link active')
            } else {
                $("#pills-wa-tab").attr('class', 'nav-link active')
            }
            $("#pills-ma-snapshot").attr('class', 'tab-pane fade')
            $("#pills-wa").attr('class', 'tab-content tab-pane fade active show')
            this.setState({
                tabChange: false,
                newAlerts: false
            })
        }
    }
    downloadDeviceLogs = (row) => {
        let filename = '';
        filename = row.download_url;
        // EMGT-35 : User not able to download logs in EM snapshot
        this.setState({
            showLoader: true
        })
        ApiCall(urlMapping.deviceLogDownloadNew(filename), (response) => {
            if (parseInt(response?.response?.status) === 200) {
                const url = window.URL.createObjectURL(new Blob([response?.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `EM_snapshot_${filename}`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.setState({
                    showLoader: false
                })
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({ showRefreshModal: true })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        });
    }
    getDeviceLogList = () => {
        this.setState({
            showLoader: true,
        });
        ApiCall(urlMapping.deviceLogList(this.state.pageNo, this.state.pageSize), (response) => {
            if (response?.results) {
                const data = response?.results;
                const totalCount = response?.count;
                this.setState({
                    deviceLogData: [...data],
                    totalCount,
                    showLoader: false,
                })
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showLoader: false,
                        showRefreshModal: true
                    })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        showLoader: false
                    })
                }
            }
        })
    }

    resetPopup = () => {
        this.setState({
            show: false,
            error: false,
        }, () => {
            $("#errorMessage").modal("hide");
        })
    }

    generateHandler = () => {
        this.setState({ showLoader: true })

        ApiCall(urlMapping.deviceLogDownload(), (response) => {
            this.setState({ showLoader: false })
            if (parseInt(response?.response?.status) === 201) {
                this.getDeviceLogList();
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({ showRefreshModal: true })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        })
    }

    paginationChange = (e) => {
        this.setState({
            pageNo: e
        }, () => {
            this.getDeviceLogList();
        });
    }
    pageSizeChange = (e) => {
        this.setState({ pageSize: e }, () => { this.getDeviceLogList(); });
    }

    maSnapshotPageRefresh = () => {
        this.getDeviceLogList();
    }

    getSystemAlertsCount = () => {
        ApiCall(urlMapping.getSystemAlertsCount(), (response) => {
            if (response?.Success) {
                if (response?.data) {
                    let { newSystemAlerts } = this.state;
                    newSystemAlerts =
                        (response?.data?.new_memory_alerts || 0) +
                        (response?.data?.new_cpu_alerts || 0) +
                        (response?.data?.new_hard_disc_alerts || 0) +
                        (response?.data?.new_user_login_alerts || 0);
                    this.setState({ newSystemAlerts, countUpdate: Math?.random() });
                }
            }
        })
    }
    startTroubleshoot = () => {
        this.setState({            
            showLoader: true
        })
        ApiCall(urlMapping.troubleshooting(), (response) => {
            if (response.response.status === 200) {
                this.setState({
                    troubleshooting: true,
                    showLoader: false,
                    title: "Troubleshooting",
                    message: response?.message,
                    clientId: response.client_id,
                    show: true,
                    error: false,
                    delete: false
                }, () => {
                    $("#errorMessage").modal("show")
                }
                )
            } else {
                this.setState({
                    showLoader: false,
                    title: "Troubleshooting",
                    message: response?.response?.data?.error ? response?.response?.data?.error : "Something went wrong!",
                    show: true,
                    error: true,
                    delete: false
                }, () => {
                    $("#errorMessage").modal("show")
                }
                )
            }
        })
    }
    EndTroubleshoot = () => {
        this.setState({
            showLoader: true
        })
        ApiCall(urlMapping.EndTroubleshooting(), (response) => {
            if (response.response.status === 200) {
                this.setState({
                    troubleshooting: false,
                    showLoader: false,
                    title: "Troubleshooting",
                    message: response?.message,
                    clientId: "",
                    show: true,
                    error: false,
                }, () => {
                    $("#errorMessage").modal("show")
                }
                )
            } else {
                this.setState({
                    showLoader: false,
                    title: "Troubleshooting",
                    message: response?.response?.data?.error,
                    show: true,
                    error: true,
                }, () => {
                    $("#errorMessage").modal("show")
                }
                )
            }
        })
    }
    checkStatus = () => {
        this.setState({
            troubleshooting: true
        })
        ApiCall(urlMapping.checkStatus(), (response) => {
            if (response.response.status === 200) {
                this.setState({
                    showLoader: false,
                    title: "Troubleshooting Status",
                    message: response?.status,
                    show: true,
                    error: false,
                }, () => {
                    $("#errorMessage").modal("show")
                }
                )
            } else {
                this.setState({
                    showLoader: false,
                    title: "Troubleshooting Status",
                    message: response?.error,
                    show: true,
                    error: true,
                }, () => {
                    $("#errorMessage").modal("show")
                }
                )
            }
        })
    }
    checkTroubleshoot = () => {
        this.setState(
            {
                title: "Troubleshoot",
                message:
                    "Troubleshoot allows Inseego support team to access the set-up through SSH. Please confirm if you want to proceed. ",
                show: false,
                error: true,
                delete: true,
                show: true
            },
            () => {
                $("#errorMessage").modal("show")
            }
        );
    }

    render() {
        return (
            <div>
                <SideMenu />
                {this.state.showLoader && <Loader />}
                <section id="rhsMain" className={this.props.open ? "rhs-main" : "rhs-main expand-w"}>
                    <div key={this.state.countUpdate}><Header count={this.state.newSystemAlerts} /></div>
                    <div className="no-bg page-height">
                        <div className="page-header device-head">
                            <ul className="tab-nav nav nav-pills ml-0 mr-0" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link active" id="pills-ma-snapshot-tab" data-toggle="pill" href="#pills-ma-snapshot"
                                        role="tab" aria-controls="pills-ma-snapshot" aria-selected="false" onClick={() =>
                                            this.setState({ selectedTab: "Snapshots" })}>EM Snapshot</a>

                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link"
                                        id="pills-wa-tab"
                                        data-toggle="pill"
                                        href="#pills-wa"
                                        role="tab"
                                        aria-controls="pills-wa"
                                        aria-selected="false"
                                        onClick={() =>
                                            this.setState({
                                                selectedTab: "Warning&Alerts",
                                                deviceSeverity: ''
                                            })
                                        }
                                    >
                                        CPE Device Alerts
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link"
                                        title='SD Edge Manager host system alerts'
                                        id="pills-sa-tab"
                                        data-toggle="pill"
                                        href="#pills-wa"
                                        role="tab"
                                        aria-controls="pills-wa"
                                        aria-selected="false"
                                        onClick={() =>
                                            this.setState({
                                                selectedTab: "SystemAlerts",
                                                deviceSeverity: ''
                                            })
                                        }
                                    >
                                        Host System Alerts
                                    </a>
                                </li>
                            </ul>
                        </div>
                        {this.state.selectedTab === "Snapshots" && <div className="tab-content" id="pills-tabContent">
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="pills-ma-snapshot" role="tabpanel" aria-labelledby="pills-ma-snapshot-tab">
                                    <div className="white-bg">
                                        <div className="ta-box set-pos mb-0">
                                            <div>
                                                <div className="">
                                                    <div>
                                                        {this.state.troubleshooting && <><button className="btn btn-primary mr-2"
                                                            onClick={this.checkStatus}>Check Status</button>
                                                        </>}
                                                        <button className="btn btn-primary mr-2"
                                                            onClick={() => this.state.troubleshooting ? this.EndTroubleshoot() :
                                                                this.checkTroubleshoot()}>{this.state.troubleshooting ? "End Troubleshoot"
                                                                    : "Troubleshoot"}</button>
                                                    </div>
                                                    {this.state.troubleshooting && <div className='text-right client-id'><label>Client ID: </label>
                                                        <span>{this.state.clientId}</span></div>}
                                                </div>
                                                {/* <button className="btn btn-primary ml-2 btn-top-space"
                                                    onClick={() => this.generateHandler()}>Troubleshoot</button> */}
                                            </div>
                                            <div className="action-btn d-flex align-items-center">
                                                <button onClick={() => this.maSnapshotPageRefresh()}><img src="/images/svg-icons/refreshing.svg" alt="" /> Refresh</button>

                                            </div>
                                        </div>
                                        <p>Collect debug logs and operation logs of the 5G SD EDGE Manager.</p>
                                        <div className="row no-gutters">
                                            <div className="col-md-4 col-sm-5">
                                                <div className="form-group ml-1">
                                                    <label>Category</label>
                                                    <select className="form-control">
                                                        <option>Debug Logs</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-2">
                                                <label className="d-block">&nbsp;</label>
                                                <button className="btn btn-primary ml-2 btn-top-space"
                                                    onClick={() => this.generateHandler()}>Generate</button>
                                            </div>
                                        </div>
                                        <div className="table-box">
                                            <div className="d-flex align-items-center page-sub-title">
                                                <strong>Debug Logs</strong>
                                            </div>
                                            <div>

                                            </div>
                                            < DataTable
                                                className="new-device-list"
                                                columns={
                                                    this.state.columns
                                                }
                                                pagination
                                                paginationServer
                                                onChangePage={(e) => this.paginationChange(e)}
                                                onChangeRowsPerPage={(e) => this.pageSizeChange(e)}
                                                paginationTotalRows={this.state.totalCount}
                                                data={this.state.deviceLogData}
                                                highlightOnHover
                                                fixedHeader
                                                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {this.state.selectedTab === "Warning&Alerts" && (
                            <div
                                className="tab-content tab-pane fade show"
                                id="pills-wa"
                                role="tabpanel"
                                aria-labelledby="pills-wa-tab"
                            >
                                <Warnings
                                    deviceSeverity={this.state.deviceSeverity}
                                />
                            </div>
                        )}
                        {this.state.selectedTab === "SystemAlerts" &&
                            <div className="tab-content tab-pane fade show"
                                id="pills-wa"
                                role="tabpanel"
                                aria-labelledby="pills-sa-tab"
                            >
                                <SystemAlerts
                                    deviceSeverity={this.state.deviceSeverity}
                                    newAlerts={this.state.newAlerts}
                                    getSystemAlertsCount={this.getSystemAlertsCount}
                                />
                            </div>}
                        {(this.state.show) &&
                            <ErrorPopup
                                title={this.state.title}
                                message={this.state.message}
                                error={this.state.error}
                                delete={this.state.delete}
                                onSubmit={this.startTroubleshoot}
                                reset={this.resetPopup}
                            />
                        }
                    </div>
                </section>
                {this.state.showRefreshModal && <ErrorHandlePopup />}
                {(this.state.isAxiosError) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.setState({ isAxiosError: false })}
                    />
                }
            </div>

        );

    }
}
const mapStateToProps = (state) => {
    return ({
        authenticated: state.auth.authenticated,
        open: state.displaySidebarMenu.openSide,
    });
}
export default withRouter(connect(mapStateToProps)(Troubleshooting));
